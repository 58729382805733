<template>
    <footer class="socials-footer">
        <div class="socials-footer__wrapper">
            <div class="socials-footer__text">
                Made with <font-awesome-icon icon="fa-solid fa-heart"/> by
                @pup_blake
            </div>
            <div class="socials-footer__icons">
                <a v-for="link in socials"
                   :href="link.link"
                   :title="link.title"
                   target="_blank">
                    <font-awesome-icon :icon="`${link.icon}`"/>
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'socials-footer',
        data() {
            return {
                socials: [
                    // {
                    //     title: 'YouTube',
                    //     link:  '#',
                    //     icon:  'fa-youtube',
                    // },
                    {
                        title: 'Discord',
                        link:  'https://discord.gg/QpAN7mvMyV',
                        icon:  'fa-brands fa-discord',
                    },
                    {
                        title: 'Steam',
                        link:  'https://store.steampowered.com/app/3326280',
                        icon:  'fa-brands fa-steam',
                    },
                    {
                        title: 'E-mail',
                        link:  'mailto:pup.blake.dev@gmail.com',
                        icon:  'fas fa-envelope',
                    },
                    {
                        title: 'Telegram Channel',
                        link:  'https://t.me/arctic_wolves_game',
                        icon:  'fa-brands fa-telegram',
                    },
                    // {
                    //     title: 'X (Twitter)',
                    //     link:  'https://twitter.com/ArcticWolvesDev',
                    //     icon:  'fa-brands fa-x-twitter',
                    // },
                    // {
                    //     title: 'Patreon',
                    //     link:  'https://patreon.com/ArcticWolvesGame',
                    //     icon:  'fa-patreon',
                    // },
                ],
            };
        },
    };
</script>
