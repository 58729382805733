// vue
import { createApp } from 'vue';
import App           from './App.vue';
import router        from './router';
import store         from './store';
import 'swiper/swiper-bundle.min.css';

// fa
import { library }         from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faTelegram,
    faYoutube,
    faDiscord,
    faPatreon,
    faXTwitter,
    faSteam,
}                          from '@fortawesome/free-brands-svg-icons';
import {
    faPaw,
    faHeart,
    faBell,
    faPlayCircle,
    faEnvelope,
    faGamepad
}                          from '@fortawesome/free-solid-svg-icons';

library.add(faTelegram, faYoutube, faDiscord, faPatreon, faHeart, faPaw, faBell, faPlayCircle, faXTwitter, faEnvelope, faSteam, faGamepad);

// styling
import '@/scss/app.scss';
import { updateTheme }     from '@/theming/theme-manager';

createApp(App).use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app');

updateTheme('dark');
